.container2{
    display: flex;
    align-content: center;
    flex-direction: column;
    margin: 0px auto;
    width: 700px; /* 90% of the parent container's width */
    min-width: 70vh;
    margin-top: 5vh; /* 5% of viewport height */
    margin-bottom: 5vh; /* 5% of viewport height */
    border-radius: 40px;
    background: #ffffff;
    padding: 20px;
}

.title-uu{
    color: #1608de;
    margin-top: 20px;
    margin-left: 50px;
    font-size: 18px;
    font-weight: 700;
}

.update-teachers button{
    display: flex;
    margin-left: 50px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    color: #fff;
    background: #0700d2;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
}

.update-students button{
    display: flex;
    margin-left: 50px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    color: #fff;
    background: #0700d2;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
}

.email-field-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}

.email-field-uu-input input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.name-field-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}

.name-field-uu-input input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.lastname-field-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}

.lastname-field-uu-input input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.phone-number-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}

.phone-number-uu-input input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.img-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}

.img-uu-input input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.password-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}

.password-uu-input input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.id-field-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}

.id-field-uu-input input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.cert-field-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}

.cert-field-uu-input input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.return-home-uu{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 17px;
    color: #003fb4;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.update-button{
    margin-left: 50px;
    margin-top: 25px;
    font-size: 20px;
}
