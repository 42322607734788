/* 🔹 Estilo del contenedor de la lista de personas */
.listado-personas {
    margin: 20px auto;
    padding: 15px;
    max-width: 800px;
    background: #ffffff; /* Fondo blanco */
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* 🔹 Título de la lista */
.listado-personas h2 {
    text-align: center;
    color: #0f00e5; /* Azul fuerte */
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
}

/* 🔹 Tabla de personas */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    max-width: 100%;
    overflow-x: auto; /* Hace la tabla scrollable si es necesario */
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #d1d1d1; /* Línea separadora gris claro */
}

th {
    background-color: #0f00e5;
    color: white;
    font-weight: 600;
}

tr:nth-child(even) {
    background-color: #f5f5f5;
}

tr:hover {
    background-color: #eaeaea;
}

/* 🔴 Botón de eliminar */
.btn-eliminar {
    background-color: #cb0000;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.btn-eliminar:hover {
    background-color: #970000;
}

td:last-child {
    text-align: center;
}

/* 🔹 Organiza los botones de la barra inferior en una sola línea */
.barra-inferior-flex {
    display: flex;
    justify-content: space-between; /* ✅ Asegura que los botones estén alineados en fila */
    align-items: center;
    gap: 10px; /* Espaciado uniforme entre botones */
    width: 100%;
}

/* 🔹 Botón de Administrar Curso al lado de Evaluación Final */
.btn-menu {
    background-color: #0f00e5;
    color: white;
    padding: 12px 20px; /* ✅ Más padding para mejorar el tamaño */
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    transition: background 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px; /* ✅ Espacio entre el ícono y el texto */
}

.btn-menu:hover {
    background-color: #0b00b2;
}

/* 📌 Submenú correctamente alineado debajo del botón */
.menu-container {
    position: relative; /* ✅ Asegura que el submenú se posicione relativo al botón */
    display: inline-block;
}

/* 📌 Estilos del Submenú */
.submenu {
    position: absolute;
    background: white;
    border: 1px solid #d1d1d1;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 8px;
    display: flex;
    flex-direction: column;
    width: 180px;
    border-radius: 6px;
    z-index: 1000;
    top: 100%; /* ✅ Coloca el submenú justo debajo del botón */
    left: 50%; /* ✅ Centra el submenú respecto al botón */
    transform: translateX(-50%); /* ✅ Ajusta la alineación */
}

/* 🔹 Ajuste del submenú en pantallas pequeñas */
@media (max-width: 768px) {
    .submenu {
        left: 0;
        transform: none;
    }
}

/* 📌 Efecto al pasar el mouse */
.submenu button {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    color: #0f00e5;
    font-weight: bold;
    transition: background 0.3s ease-in-out;
    width: 100%;
}

.submenu button:hover {
    background-color: #f5f5f5;
}

/* 🔹 Fondo oscuro detrás del modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* 🔹 Contenido del modal */
.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px; /* Evita que sea muy ancho en pantallas grandes */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
}

/* 🔹 Efecto de animación */
@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1); }
}

/* 🔹 Título del modal */
.modal-content h2 {
    color: #0f00e5;
}

/* 🔹 Etiquetas */
.modal-content label {
    display: block;
    text-align: left;
    margin-top: 10px;
    font-weight: bold;
}

/* 🔹 Campos de entrada */
.modal-content input,
.modal-content textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* 🔹 Contenedor de botones dentro del modal */
.modal-buttons {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

/* 🔹 Botones del modal */
.btn-guardar {
    background-color: #0f00e5;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1; /* Hace que ambos botones sean del mismo tamaño */
}

.btn-cancelar {
    background-color: #cb0000;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    flex: 1;
}

.btn-guardar:hover {
    background-color: #0b00b2;
}

.btn-cancelar:hover {
    background-color: #970000;
}

/* 🔹 Ajuste para pantallas pequeñas */
@media (max-width: 500px) {
    .modal-content {
        width: 90%;
    }

    .btn-guardar, .btn-cancelar {
        font-size: 14px;
        padding: 8px;
    }
}


/* Ajustar el tamaño del dropdown */
.modal-content select {
    width: 100%; /* Asegura que ocupe todo el ancho disponible */
    padding: 10px; /* Agrega un poco de espacio dentro del dropdown */
    font-size: 16px; /* Aumenta el tamaño de la fuente */
    border-radius: 5px; /* Bordes redondeados */
    border: 1px solid #ccc; /* Borde gris claro */
    margin-top: 5px; /* Espacio entre el label y el dropdown */
    box-sizing: border-box; /* Asegura que el padding no afecte el tamaño total */
}

.modal-content select:focus {
    border-color: #0f00e5; /* Bordes azules al enfocar el campo */
    outline: none; /* Elimina el contorno predeterminado */
}

.modal-content select option {
    font-size: 16px; /* Aumenta el tamaño de la fuente de las opciones */
}