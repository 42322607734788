.contenido-tablon {
    display: flex;    
    flex-direction: column;
    width: 1150px;
    background: transparent; 
  }

  .contenido-tablon .imagen-portada{
    width: 100%;
    height: 220px;
    opacity: 0.75;
  }

  .contenido-tablon .contenido{
    display: flex;    
    flex-direction: row;
    width: 100%;
    align-items: flex-start; /* Alinea los elementos al inicio verticalmente */
    background: transparent;
  }

  .contenido-tablon .contenido .tablon{
    flex: 2; /* Ocupa más espacio */   
    flex-direction: column;
    margin-right: 20px;
  }

  .right-side{
    flex: 1; /* Ocupa menos espacio */
    height: auto;
    justify-content: space-between;
    margin-left: 20px;
  }

  .zoom-url{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #003fb4;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
  }

  .calendario{
    flex: 1; /* Ocupa menos espacio */
    height: auto;
    justify-content: space-between;
  }