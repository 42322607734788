.anuncio-tablon{
    display: flex;
    flex-direction: column;
    width: 95%;
    height: auto;
    background: transparent;
    margin: auto;
    margin-bottom: 50px;
}

.anuncio-tablon .cabecera{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: left;
    margin-bottom: 20px;
}
.anuncio-tablon .contenido{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background: transparent;
    justify-content: center;
    margin-bottom: 50px;
}
.anuncio-tablon .contenido .imagen{
    width: 100%;
    height: auto;
    justify-content: center;
    margin-bottom: 50px;
}

.anuncio-tablon .caja-comentarios{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background: transparent;
    margin-bottom: 50px;
}
.anuncio-tablon .caja-comentarios input{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    background: transparent;
    border-radius: 10px;
}

.comentarios{
    display: flex;
    flex-direction: column;
}

.comment{
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.submit-comment button{
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 20px;
    color: #fff;
    background: #1329cd;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 15px;
    cursor: pointer;
    &:hover {
        background: #00408a;
        color: #b9b9b9;
    }
}