.fullpage{
    height: 100%;
    width: 100%;
}

.background img{
    margin: auto;
    position: absolute;
    width: 154vh;
    height: 100vh;
    margin-left: -154vh;
    opacity: 0.75;
}

.container{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: 600px;
    height: 100vh;
    background: #ffffff;
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 40px;
}

.text{
    color: #0f00e5;
    font-size: 48px;
    font-weight: 700;
}

.underline{
    width: 61px;
    height: 6px;
    background: #0f00e5;
    border-radius: 9px;
}

.logo img{
    height: 200px;
    height: 200px;
    margin: auto;
    object-fit: cover;
}

.login-text{
    margin-top: 60px;
    margin-left: 62px;
    color: #5d5d5dc4;
}

.inputs{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}

.input.error{
    border: 1px solid red;
}

.input img{
    margin: 0px 30px;
    opacity: 0.7;
}

.show-password img{
    margin: 0px -50px;
}

.show-password button{
    border-radius: 50px;
    border-color: #ffffff00;
    background: #eaeaea;
    margin: 0px 15px;
    width: 40px;
    cursor: pointer;
    &:disabled {
        opacity: 0.4;
        cursor: default;
    }
}

.show-password button:not(:disabled):hover {
    background: #aeaeae;
}

.input input{
    height: 50px;
    width: 400px;
    color: #797979;
    font-size: 19px;
    background: transparent;
    border: none;
    outline: none;
}

.input input[type="email"]::placeholder {
    color: #4b4b4b; /* Change the color to your desired color */
    opacity: 0.6;
}

.input input[type="password"]::placeholder {
    color: #4b4b4b; /* Change the color to your desired color */
    opacity: 0.6;
}

.forgot-password{
    padding-left: 62px;
    margin-top: 27px;
    color: #797979;
    font-size: 16px;
}

.forgot-password span{
    color: #003fb4;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.error-message{
    color: #cb0000;
    margin: 60px auto;
    font-size: 16px;
}

.submit-container{
    display: flex;
    margin: -45px auto;
}

.submit-container button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 60px;
    color: #fff;
    background: #0700d2;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
    &:disabled {
        background: #5a5a5a;
        color: #b9b9b9;
        cursor: default;
    }
}

.submit-container3{
    display: flex;
    margin: 65px auto;
}

.submit-container3 button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 60px;
    color: #fff;
    background: #0700d2;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
}

.create-account{
    color: #797979;
    font-size: 16px;
    margin: 25px auto;
}

.create-account span{
    color: #003fb4;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
