.mostrar-links{
    padding: 20px;
}

.mostrar-links button{
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 40px;
    color: #fff;
    background: #0700d2;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
}

.links-table-holder{
    padding: 20px;
}