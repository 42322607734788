.boton-menu-desplegable{
    padding: 5px;
    font-size: 18px;
    color: #003fb4;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.boton-menu-desplegable .texto{
    width: max-content;
    height: max-content;
    font-size: 15px;
}