.class-name{
    margin-left: 50px;
    margin-top: 40px;
    font-size: 20px;
}

.class-name input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.class-description{
    margin-left: 50px;
    margin-top: 40px;
    font-size: 20px;
}

.class-description input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.teacher-id{
    margin-left: 50px;
    margin-top: 40px;
    font-size: 20px;
}

.teacher-id input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.class-id{
    margin-left: 50px;
    margin-top: 40px;
    font-size: 20px;
}

.class-id input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.student-id{
    margin-left: 50px;
    margin-top: 40px;
    font-size: 20px;
}

.student-id input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.zoom-url-a{
    margin-left: 50px;
    margin-top: 40px;
    font-size: 20px;
}

.zoom-url-a input{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.insertar-fecha{
    margin-left: 50px;
    margin-top: 40px;
    font-size: 20px;
}

.insertar-fecha button{
    margin-top: 6px;
    height: 30px;
    width: 80%;
}

.submit button{
    display: flex;
    margin-left: 50px;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 60px;
    color: #fff;
    background: #0700d2;
    border-radius: 10px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
}
