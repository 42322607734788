.administration button{
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 35px;
    color: #fff;
    background: #5f9ff1;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 30px;
    margin-bottom: 10px;
    margin-right: 30px;
    cursor: pointer;
    &:hover {
        background: #00408a;
        color: #b9b9b9;
    }
}

.a-underline{
    margin: auto;
    width: 98%;
    height: 1px;
    background: #03002c;
    border-radius: 9px;
    margin-top: 14px;
    margin-bottom: 14px;
}