.assignment-title{
    font-size: 20px;
    margin-bottom: 50px;
    margin-top: 50px;
}

.folder-underline{
    margin: auto;
    width: 100%;
    height: 3px;
    background: #090083;
    border-radius: 9px;
    margin-top: 14px;
}

.folder-name{
    margin-top: 6px;
    font-size: 20px;
}

.assignment-title{
    font-size: 18px;
    color: #7b7b7b;
}
.assignment-title button{
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 60px;
    color: #fff;
    background: #0700d2;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
}

.button button{
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 60px;
    color: #fff;
    background: #0082d2;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        background: #003e8a;
        color: #b9b9b9;
    }
}

.assignment-holder{
    border: 1px solid black;
    padding: 25px;
    display: flex;
    flex-flow: column;
    align-content: center;
    width: 110vh;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.remove-file span{
    color: #003fb4;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.folder-name button{
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 30px;
    color: #fff;
    background: #0700d2;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
    right: auto;
    margin-top: 10px;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
}

.underline-assignments {
    width: 90%;
    height: 1px;
    align-self: center;
    background: #03002c;
    border-radius: 9px;
    margin-top: 14px;
    margin-bottom: 14px;
}