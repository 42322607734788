.formulario-subida {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
}

.campo {
    margin-bottom: 15px;
}

.campo label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

input[type="file"], select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.archivo-preview {
    background: #e3f2fd;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 14px;
}

.boton-subir {
    background: #28a745;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.boton-subir:hover {
    background: #218838;
}

.tabla-archivos {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.tabla-archivos th, .tabla-archivos td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}

.boton-descargar, .boton-eliminar {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.boton-descargar {
    background: #007bff;
    color: white;
}

.boton-eliminar {
    background: #dc3545;
    color: white;
}

/* 📌 Estilos para la tabla */
.tabla-archivos {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* 📌 Estilos para las cabeceras */
.tabla-archivos thead {
    background-color: #4CAF50;
    color: white;
}

.tabla-archivos th, 
.tabla-archivos td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

/* 📌 Efecto hover */
.tabla-archivos tbody tr:hover {
    background-color: #f1f1f1;
}

/* 📌 Botones */
.boton-descargar, .boton-eliminar {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease;
}

/* 📌 Botón de descarga */
.boton-descargar {
    background-color: #2196F3;
    color: white;
}

.boton-descargar:hover {
    background-color: #0b7dda;
}

/* 📌 Botón de eliminar */
.boton-eliminar {
    background-color: #f44336;
    color: white;
}

.boton-eliminar:hover {
    background-color: #d32f2f;
}

/* 📌 Responsivo */
@media screen and (max-width: 768px) {
    .tabla-archivos th, .tabla-archivos td {
        padding: 8px;
        font-size: 14px;
    }

    .boton-descargar, .boton-eliminar {
        padding: 6px 12px;
        font-size: 14px;
    }
}
