table{
    align-self: center;
    margin-top: 20px;
    width: 100%;
    border: 1px solid;
    border-collapse: collapse;
    table-layout: fixed; /* Fix table layout */
}

td{
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid black;
    border: 1px solid;
    width: auto; /* Let the width adjust based on content */
    max-width: 300px; /* Adjust the maximum width of table cells */
    overflow: hidden; /* Hide overflow content */
    white-space: nowrap; /* Prevent text wrapping */
    overflow-x: auto; /* Enable horizontal scrolling */
}

th{
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid black;
    background-color: rgb(0, 0, 221);
    color: white;
}

tr{
    &:hover{
        background-color: skyblue;
    }
}

.mostrar-usuarios button{
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 60px;
    color: #fff;
    background: #0700d2;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
        background: #05008a;
        color: #b9b9b9;
    }
}


