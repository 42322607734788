.contenedor-materias {
  display: flex;
  justify-content: center; /* Centra los elementos */
  flex-wrap: wrap;
  padding: 24px;
}

.contenedor > .componente {
  margin-bottom: 20px; /* Espacio entre los componentes */
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
}

/* Paginación centrada */
.paginacion {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px; /* Espaciado entre los botones y el número de página */
  margin-top: 20px;
}

.paginacion button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.paginacion button:hover {
  background-color: #0056b3;
}

.paginacion button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Estiliza el número de página */
.pagina-info {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align: center;
}
