.pie-pagina{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;
    width: 105vh;
    background: white;
    border-radius: 15px;
}

.pie-pagina .imagen{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-block: 30px;
    width: 100vh;
    height: 250px;
    opacity: 0.85;
    border-radius: 10px;
    object-fit: cover;
}

footer{
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}